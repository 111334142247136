<div class="switcher-container">
	
	<div class="header">
		
		<ul>
			
			<li class="header-button">
				
				<a target="_self" href="https://store.neatlyliving.com">Build Plans</a>
			
			</li>
			
			<li class="header-button">
				
				<a target="_self" href="https://www.liketoknow.it/neatlyliving">LIKEToKNOW.It</a>
			
			</li>
			
			<li class="header-button">
				
				<a target="_self" href="https://neatlyliving.com/">Blog</a>
			
			</li>
			
			<li class="header-button">
				
				<a target="_self" href="https://www.amazon.com/shop/neatly.living">Amazon Store Front</a>
			
			</li>
			
			<li class="header-button">
				
				<a target="_self" href="https://www.pinterest.com/neatlyliving/">Pinterest</a>
			
			</li>
		
		</ul>
	
	</div>
	
	<div class="body">
		
		<div class="body-title">Features</div>
		
		<ul>
			
			<li class="body-button">
				
				<a target="_self" href="https://www.apartmenttherapy.com/bedroom-redo-black-shiplap-accent-wall-36767492?utm_source=like2buy.curalate.com&crl8_id=9a0405e9-17ea-4c52-8ebe-edc6004006aa" [ngStyle]="{'background-image': 'url(' + 'https://neatlyliving.com/wp-content/uploads/2020/06/DSC07560-2.jpg' + ')'}">
				</a>
				
				<div class="button-title">Apartment Therapy</div>
			
			</li>
			
			<li class="body-button">
				
				<a target="_self" href="https://homeplistic.com/blogs/homeplistic-journal/shelfie-love" [ngStyle]="{'background-image': 'url(' + 'https://neatlyliving.com/wp-content/uploads/2020/06/DSC07541.jpg' + ')'}">
				</a>
				
				<div class="button-title">Homeplistic</div>
				
			</li>
		
		</ul>
	
	</div>
	
	<div class="footer">
		
		<i class="far fa-copyright"></i>
		
		<a href="https://neatlyliving.com">Neatly Living 2020</a>
	
	</div>

</div>
